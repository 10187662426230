<template>
  <template-card
    :name="name"
    preview-width="250px"
    preview-height="160px"
    icon="$paper_items"
    @dragend:item="addItemDrop()"
    @click:item="addItem()" />
</template>
<script>
import paperBuilderToolMixin from '@/mixins/paperBuilderToolMixin'
import { v4 as uuid } from 'uuid'
import { mapGetters } from 'vuex'
import TemplateCard from './TemplateCard.vue'

export default {
  components: { TemplateCard },
  mixins: [
    paperBuilderToolMixin
  ],
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    canvas () {
      return this.paperBuilder?.canvas || []
    }
  },
  methods: {
    addItem () {
      const hasProductTable = this.canvas.some((item) => item.type === 'products')
      if (!hasProductTable) {
        const { left, top } = this.paperBuilderDrop || { left: '20px', top: '20px' }
        this.addPaperBuilderCanvas({
          id: this.paperBuilderId,
          data: {
            id: uuid(),
            type: 'products',
            tag: 'div',
            data: null,
            fx: {},
            properties: {
              style: {
                position: 'absolute',
                left,
                top,
                width: '250px',
                height: '160px',
                overflow: 'hidden'
              }
            },
            children: [
              {
                tag: 'table',
                data: null,
                fx: {},
                properties: {
                  style: {
                    width: '100%',
                    borderCollapse: 'collapse'
                  }
                },
                children: [
                  {
                    tag: 'thead',
                    data: null,
                    fx: {},
                    properties: {},
                    children: [
                      {
                        tag: 'tr',
                        data: null,
                        fx: {},
                        properties: {
                          style: {
                            fontSize: '14px',
                            height: '36px',
                            fontWeight: 'bold',
                            fontStyle: 'normal',
                            color: '#000000'
                          }
                        },
                        children: [
                          {
                            tag: 'td',
                            data: null,
                            fx: {},
                            properties: {
                              style: {
                                textAlign: 'left',
                                width: '100px',
                                padding: '0 4px'
                              }
                            },
                            children: [
                              'ชื่อสินค้า'
                            ]
                          },
                          {
                            tag: 'td',
                            data: null,
                            fx: {},
                            properties: {
                              style: {
                                textAlign: 'right',
                                padding: '0 4px'
                              }
                            },
                            children: [
                              'รูปภาพสินค้า'
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    tag: 'tbody',
                    data: null,
                    fx: {},
                    properties: {},
                    children: [
                      {
                        tag: 'tr',
                        data: 'purchases',
                        fx: {},
                        properties: {
                          style: {
                            fontSize: '14px',
                            height: '32px',
                            fontWeight: 'normal',
                            fontStyle: 'normal',
                            color: '#000000'
                          }
                        },
                        children: [
                          {
                            tag: 'td',
                            data: 'name',
                            fx: {},
                            properties: {
                              style: {
                                textAlign: 'left',
                                width: '100px',
                                borderTop: '1px solid #ddd',
                                borderBottom: '1px solid #ddd',
                                padding: '0 4px'
                              }
                            },
                            children: []
                          },
                          {
                            tag: 'td',
                            data: null,
                            fx: {},
                            properties: {
                              style: {
                                textAlign: 'right',
                                borderTop: '1px solid #ddd',
                                borderBottom: '1px solid #ddd',
                                padding: '0 4px'
                              }
                            },
                            children: [{
                              tag: 'img',
                              data: null,
                              fx: {},
                              properties: {
                                src: 'picture_url',
                                style: {
                                  height: '20px',
                                  width: '20px'
                                }
                              }
                            }]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        })
      } else {
        console.log('Already')
      }
    }
  }
}
</script>
<style scoped>

</style>
